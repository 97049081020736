<template>
  <div>
    <!-- <v-avatar
      v-for="n in 6"
      :key="n"
      :color="`grey ${n === 1 ? 'darken' : 'lighten'}-1`"
      :size="n === 1 ? 36 : 20"
      class="d-block text-center mx-auto mb-9"
      link
    ></v-avatar> -->
    <v-list>
      <v-list-item class="pt-0 px-2">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>John Leider</v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            link
            to="/dashboard"
            v-bind="attrs"
            v-on="on"
            active-class="border"
          >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
        </template>
        <span>Home</span></v-tooltip
      >
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            link
            to="/domain"
            v-bind="attrs"
            v-on="on"
            active-class="border"
          >
            <v-list-item-icon>
              <v-icon>mdi-tag-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>域名管理</v-list-item-title>
          </v-list-item>
        </template>
        <span>域名管理</span></v-tooltip
      >
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            link
            to="/organ"
            v-bind="attrs"
            v-on="on"
            active-class="border"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>组织用户</v-list-item-title>
          </v-list-item>
        </template>
        <span>组织用户</span></v-tooltip
      >
    </v-list>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.border {
  border-left: 4px solid #0ba518;
}
</style>
