var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list',[_c('v-list-item',{staticClass:"pt-0 px-2"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":"https://randomuser.me/api/portraits/men/85.jpg"}})],1),_c('v-list-item-title',[_vm._v("John Leider")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('v-divider'),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":"/dashboard","active-class":"border"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-title',[_vm._v("Home")])],1)]}}])},[_c('span',[_vm._v("Home")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":"/domain","active-class":"border"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-tag-multiple")])],1),_c('v-list-item-title',[_vm._v("域名管理")])],1)]}}])},[_c('span',[_vm._v("域名管理")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":"/organ","active-class":"border"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-multiple")])],1),_c('v-list-item-title',[_vm._v("组织用户")])],1)]}}])},[_c('span',[_vm._v("组织用户")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }